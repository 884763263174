//=======================| App Configuration |========================//
export default {
	appUrlBackEnd: 'https://apifried.sislii.com',
	// appUrlBackEnd: 'http://fried.test',
	appLogo: '/static/images/logo.svg', 
	appLogoDark: '/static/images/logo-dark.svg',                        // App Logo,
	brand: 'Entre Leña y Sabor',                                        		   // Brand Name
	copyrightText: '© Todos los Derechos Reservados | Hecho por',     // Copyright Text
	//theme color
	color: {
      	primary: '#1b2831',  
      	accent : '#E21E42',
	}
}
