<template>
	<div class="admin-panel">
		<v-snackbar
			v-for="(snackbar, index) in notifications"
			v-model="snackbar.show"
			:color="snackbar.type"
			:timeout="1000"
			:top="true"
			:key="index"
		>
			{{ snackbar.message }}
		</v-snackbar>

		<v-navigation-drawer v-model="drawer" temporary dark fixed color="primary" width="260">
			<div class="site-logo mb-1  pt-2">
				<router-link to="/dashboard/reports" class="d-block ml-4">
					<img alt="site-logo" height="64" :src="appLogo" width="115" />
				</router-link>
			</div>
			<v-divider></v-divider>
			<template>
				<v-list-item two-line>
					<v-list-item-avatar>
						<img src="https://randomuser.me/api/portraits/men/85.jpg" />
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title>{{ user.name }}</v-list-item-title>
						<v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
			</template>
			<v-divider></v-divider>
			<v-list nav dense>
				<template v-for="(menuItem, key) in adminPanelMenus">
					<template v-if="menuItem.children == null">
						<v-list-item
							:key="key"
							:to="menuItem.path"
							:class="menuItem.active"
							@click="updateSidebarMenu(menuItem)"
							v-if="permissions.includes(menuItem.permissions)"
						>
							<v-list-item-icon>
								<v-icon>{{ menuItem.icon }}</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{ $t(menuItem.name) }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
					<template v-else>
						<v-list-group
							no-action
							:key="key"
							v-model="menuItem.active"
							color="accent"
							:prepend-icon="menuItem.icon"
							v-if="
								permissions.filter((x) => menuItem.permissions.includes(x)).length >
									0
							"
						>
							<template v-slot:activator>
								<v-list-item-title>{{ $t(menuItem.name) }}</v-list-item-title>
							</template>
							<template v-if="menuItem.children != null">
								<template v-for="(subItem, index) in menuItem.children">
									<template v-if="subItem.children != null">
										<v-list-group
											no-action
											sub-group
											:key="index"
											color="accent"
											v-model="subItem.active"
										>
											<template v-slot:activator>
												<v-list-item-content :key="index">
													<v-list-item-title>{{
														$t(subItem.name)
													}}</v-list-item-title>
												</v-list-item-content>
											</template>

											<v-list-item
												v-for="(subSubItem, i) in subItem.children"
												:to="subSubItem.path"
												:key="i"
											>
												<v-list-item-title>{{
													$t(subSubItem.name)
												}}</v-list-item-title>
											</v-list-item>
										</v-list-group>
									</template>
									<template v-else>
										<v-list-item
											v-if="permissions.includes(subItem.permissions)"
											@click="updateSidebarMenu(menuItem)"
											v-bind:key="index"
											:to="subItem.path"
										>
											<v-list-item-title>
												{{ $t(subItem.name) }}
											</v-list-item-title>
										</v-list-item>
									</template>
								</template>
							</template>
						</v-list-group>
					</template>
				</template>
			</v-list>
			<template v-slot:append>
				<div class="pa-2">
					<v-btn block color="accent" outlined @click="logoutUser">
						<v-icon left>mdi-exit-to-app</v-icon> Cerrar sesión
					</v-btn>
				</div>
			</template>
		</v-navigation-drawer>

		<v-app-bar color="primary" dark app class="admin-panel-toolbar">
			<!-- <v-app-bar-nav-icon
				@click.stop="drawer = !drawer"
				class="primary ml-0"
				fab
				dark
				small
			></v-app-bar-nav-icon> -->
			<v-btn class="mx-2" fab dark small color="accent" @click.stop="drawer = !drawer">
				<v-icon dark>mdi-menu</v-icon>
			</v-btn>
			<div class="d-inline-flex align-center">
				<div class="options mr-4">
					<emb-establishment></emb-establishment>
				</div>
				<div class="notifications">
					<emb-admin-block></emb-admin-block>
				</div>
			</div>
		</v-app-bar>

		<v-main>
			<!-- <v-container fill-height container-fluid> -->
			<v-layout justify-center align-center class="px-sm-2 ">
				<v-flex>
					<router-view></router-view>
				</v-flex>
			</v-layout>
			<!-- </v-container> -->
		</v-main>
	</div>
</template>

<script>
import Establishment from 'Views/Admin/Users/Component/Establishment';
import AdminBlock from 'Components/Layouts/Header/AdminBlock';
import AppConfig from 'Constants/AppConfig';
import { mapGetters } from 'vuex';

export default {
	data() {
		return {
			appLogo: AppConfig.appLogo,
			drawer: false,
			loaded: false,
			permissions: [],
			user: {}
		};
	},
	computed: {
		...mapGetters(['adminPanelMenus', 'notifications', 'loggedUser']),
	},
	async created() {
		this.user = JSON.parse(localStorage.getItem('user'));
		this.permissions = this.user.permissions;
	},
	methods: {
		updateSidebarMenu(currentMenu) {
			this.$store.dispatch('changeActiveMenu', currentMenu);
		},
		logoutUser() {
			this.$store.dispatch('logoutUser');

			this.$router.push({ name: 'home' });
		},
	},
	components: {
		embEstablishment: Establishment,
		embAdminBlock: AdminBlock,
	},
	mounted() {
	    // window.addEventListener(
	    //     "keypress",
	    //     function(e) {
	    //         if (e.which == 45) {
	    //             this.$router.push({name: 'SaleIndex'})
	    //         }
	    //     }.bind(this)
	    // );
	},
};
</script>
