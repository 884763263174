import Admin from 'Container/Adminpanel.vue';
import Middlewares from '../middlewares/';

const Reports = () => import(/* webpackChunkName: "Reports" */ 'Views/AdminPanel/Reports.vue');

const Permissions = () =>
	import(/* webpackChunkName: "Permissions" */ 'Views/Admin/Permissions/Index.vue');
const Roles = () => import(/* webpackChunkName: "Roles" */ 'Views/Admin/Roles/Index.vue');
const Users = () => import(/* webpackChunkName: "Users" */ 'Views/Admin/Users/Index.vue');
const Establishments = () =>
	import(/* webpackChunkName: "Establishments" */ 'Views/Admin/Establishments/Index.vue');
const Waiters = () => import(/* webpackChunkName: "Waiters" */ 'Views/Admin/Waiters/Index.vue');
const CulqiTokens = () =>
	import(/* webpackChunkName: "CulqiTokens" */ 'Views/Admin/CulqiTokens/Index.vue');
const Configurations = () =>
	import(/* webpackChunkName: "Configurations" */ 'Views/Admin/Configurations/Index.vue');
const Tasks = () => import(/* webpackChunkName: "tasks" */ 'Views/Admin/Tasks/Index.vue');
const Correlatives = () =>
	import(/* webpackChunkName: "Correlatives" */ 'Views/Admin/Correlatives/Index.vue');
const DistributionAreas = () =>
	import(/* webpackChunkName: "DistributionAreas" */ 'Views/Admin/DistributionAreas/Index.vue');

const Categories = () =>
	import(/* webpackChunkName: "Categories" */ 'Views/Admin/Categories/Index.vue');
const Salons = () => import(/* webpackChunkName: "Salons" */ 'Views/Admin/Salons/Index.vue');
const Tables = () => import(/* webpackChunkName: "Tables" */ 'Views/Admin/Tables/Index.vue');
const CommandZones = () =>
	import(/* webpackChunkName: "CommandZones" */ 'Views/Admin/CommandZones/Index.vue');
const UnitTypes = () =>
	import(/* webpackChunkName: "UnitTypes" */ 'Views/Admin/UnitTypes/Index.vue');

const Items = () => import(/* webpackChunkName: "Items" */ 'Views/Admin/Items/Index.vue');
const Recipes = () => import(/* webpackChunkName: "Recipes" */ 'Views/Admin/Recipes/Index.vue');
const Movements = () =>
	import(/* webpackChunkName: "Movements" */ 'Views/Admin/Movements/Index.vue');
const Inventories = () =>
	import(/* webpackChunkName: "Inventories" */ 'Views/Admin/Inventories/Index.vue');
const InventoryHistories = () =>
	import(/* webpackChunkName: "InventoryHistories" */ 'Views/Admin/InventoryHistories/Index.vue');
const Ingredients = () =>
	import(/* webpackChunkName: "Ingredients" */ 'Views/Admin/Ingredients/Index.vue');

const Orders = () => import(/* webpackChunkName: "Orders" */ 'Views/Admin/Orders/Index.vue');
const OrdersList = () => import(/* webpackChunkName: "Orders" */ 'Views/Admin/Orders/OrdersList.vue');

const OrdersCreate = () =>
	import(/* webpackChunkName: "OrdersCreate" */ 'Views/Admin/Orders/Create.vue');

const SaleIndex = () => import(/* webpackChunkName: "SaleIndex" */ 'Views/Admin/Sales/Index.vue');

const CommandAdd = () =>
	import(/* webpackChunkName: "CommandAdd" */ 'Views/Admin/Commands/Add.vue');
const Commands = () =>
	import(/* webpackChunkName: "CommandIndex" */ 'Views/Admin/Commands/Index.vue');

const People = () => import(/* webpackChunkName: "People" */ 'Views/Admin/People/Index.vue');

const FastSale = () =>
	import(/* webpackChunkName: "FastSale" */ 'Views/Admin/FastSales/Create.vue');

const DocumentIndex = () =>
	import(/* webpackChunkName: "DocumentIndex" */ 'Views/Admin/Documents/Index.vue');
const DocumentCreate = () =>
	import(/* webpackChunkName: "DocumentCreate" */ 'Views/Admin/Documents/Create.vue');
const Summaries = () =>
	import(/* webpackChunkName: "Summaries" */ 'Views/Admin/Summaries/Index.vue');
const Voided = () => import(/* webpackChunkName: "Voided" */ 'Views/Admin/Voided/Index.vue');

const Cashes = () => import(/* webpackChunkName: "cashes" */ 'Views/Admin/Cashes/Index.vue');
const Openings = () => import(/* webpackChunkName: "openings" */ 'Views/Admin/Openings/Index.vue');
const DeliveryPayment = () => import(/* webpackChunkName: "deliveryPayment" */ 'Views/Admin/SalesReceivables/DeliveryPayment.vue');
const SalesReceivables = () =>
	import(/* webpackChunkName: "SalesReceivables" */ 'Views/Admin/SalesReceivables/Index.vue');
const SaleReceivable = () =>
	import(/* webpackChunkName: "payments" */ 'Views/Admin/SalesReceivables/Payment.vue');
const OpeningHistories = () =>
	import(/* webpackChunkName: "histories" */ 'Views/Admin/OpeningHistories/Index.vue');

const DocumentsReports = () =>
	import(/* webpackChunkName: "DocumentsReports" */ 'Views/Admin/Reports/Documents/Index.vue');

	const CommandsReports = () =>
	import(/* webpackChunkName: "CommandsReports" */ 'Views/Admin/Reports/Commands/Index.vue');

export default {
	path: '/dashboard',
	component: Admin,
	redirect: '/dashboard/reports',
	children: [
		{
			path: 'reports',
			component: Reports,
			name: 'Reports',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['see_dashboard'],
			},
		},
		{
			path: 'permissions',
			component: Permissions,
			name: 'Permissions',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['permissions_manage'],
			},
		},
		{
			path: 'roles',
			component: Roles,
			name: 'Roles',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['roles_manage'],
			},
		},
		{
			path: 'users',
			component: Users,
			name: 'Users',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['users_manage'],
			},
		},
		{
			path: 'establishments',
			component: Establishments,
			name: 'Establishments',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['establishments_manage'],
			},
		},
		{
			path: 'waiters',
			component: Waiters,
			name: 'Waiters',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['waiters_manage'],
			},
		},
		{
			path: 'culqi-tokens',
			component: CulqiTokens,
			name: 'CulqiTokens',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['configurations_manage'],
			},
		},
		{
			path: 'configurations',
			component: Configurations,
			name: 'Configurations',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['configurations_manage'],
			},
		},
		{
			path: 'tasks',
			component: Tasks,
			name: 'Tasks',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['tasks_manage'],
			},
		},
		{
			path: 'correlatives',
			component: Correlatives,
			name: 'Correlatives',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['configurations_manage'],
			},
		},
		{
			path: 'distribution-areas',
			component: DistributionAreas,
			name: 'DistributionAreas',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['configurations_manage'],
			},
		},
		{
			path: 'categories',
			component: Categories,
			name: 'Categories',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['categories_manage'],
			},
		},
		{
			path: 'salons',
			component: Salons,
			name: 'Salons',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['salons_manage'],
			},
		},
		{
			path: 'tables',
			component: Tables,
			name: 'Tables',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['tables_manage'],
			},
		},
		{
			path: 'command-zones',
			component: CommandZones,
			name: 'CommandZones',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['command_zones_manage'],
			},
		},
		{
			path: 'unit-types',
			component: UnitTypes,
			name: 'UnitTypes',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['unit_types_manage'],
			},
		},
		{
			path: 'items',
			component: Items,
			name: 'Items',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['menu_manage'],
			},
		},
		{
			path: 'recipes',
			component: Recipes,
			name: 'Recipes',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['ingredients_manage'],
			},
		},
		{
			path: 'supplies',
			component: Ingredients,
			name: 'Ingredients',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['ingredients_manage'],
			},
		},
		{
			path: 'movements',
			component: Movements,
			name: 'Movements',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['see_inventory'],
			},
		},
		{
			path: 'inventories',
			component: Inventories,
			name: 'Inventories',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['see_inventory'],
			},
		},
		{
			path: 'inventory-histories',
			component: InventoryHistories,
			name: 'InventoryHistories',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['see_inventory'],
			},
		},
		{
			path: 'orders',
			component: Orders,
			name: 'Orders',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['see_orders'],
			},
		},
		{
			path: 'orders-list',
			component: OrdersList,
			name: 'OrdersList',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['see_orders'],
			},
		},
		{
			path: 'order/create',
			component: OrdersCreate,
			name: 'OrdersCretae',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['generate_commands'],
			},
		},
		{
			path: 'sale',
			component: SaleIndex,
			name: 'SaleIndex',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['generate_commands'],
			},
		},
		{
			path: 'sale/add/:id',
			component: CommandAdd,
			name: 'SaleAdd',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['generate_commands'],
			},
		},
		{
			path: 'commands',
			component: Commands,
			name: 'Commands',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['generate_commands'],
			},
		},
		{
			path: 'fast-sale/create',
			component: FastSale,
			name: 'FastSale',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['generate_commands'],
			},
		},
		{
			path: 'people/:type',
			component: People,
			name: 'People',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['customers_manage'],
			},
		},
		{
			path: 'documents',
			component: DocumentIndex,
			name: 'DocumentIndex',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['see_vouchers'],
			},
		},
		{
			path: 'document/create',
			component: DocumentCreate,
			name: 'documentCreate',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['generate_voucher'],
			},
		},
		{
			path: 'summaries',
			component: Summaries,
			name: 'Summaries',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['generate_voucher'],
			},
		},
		{
			path: 'voided',
			component: Voided,
			name: 'Voided',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['generate_voucher'],
			},
		},
		{
			path: 'cashes',
			component: Cashes,
			name: 'cashes',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['cash_manage'],
			},
		},
		{
			path: 'openings',
			component: Openings,
			name: 'openings',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['to_do_transactions_in_cash'],
			},
		},
		{
			path: 'delivery-payment/:id',
			component: DeliveryPayment,
			name: 'DeliveryPayment',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['to_do_transactions_in_cash'],
			},
		},
		{
			path: 'sales-receivables',
			component: SalesReceivables,
			name: 'SalesReceivables',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['to_do_transactions_in_cash'],
			},
		},
		{
			path: 'sale-receivable/:id',
			component: SaleReceivable,
			name: 'SaleReceivable',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['to_do_transactions_in_cash'],
			},
		},
		{
			path: 'openings-history',
			component: OpeningHistories,
			name: 'openingHistories',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['to_do_transactions_in_cash'],
			},
		},
		{
			path: 'report/documents',
			component: DocumentsReports,
			name: 'DocumentsReports',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['see_report_of_sales'],
			},
		},
		{
			path: 'report/commands',
			component: CommandsReports,
			name: 'CommandsReports',
			meta: {
				middleware: [Middlewares.auth, Middlewares.checkPermissions],
				permissions: ['see_report_of_sales'],
			},
		},
	],
};
