/* eslint-disable */
// Sidebar Routers 
export const menus = [
	{
		path: '/',
		name: 'message.home',
		icon: 'mdi-home',
		children: null,
	},
	{
		path: '/menu',
		name: 'message.menu',
		icon: 'mdi-food',
		children: null,
	},
	// {
	//    path: '/contact',
	//    name: "message.contactUs",
	//    icon: 'perm_contact_calendar',
	//    children: null
	// },
	{
		path: '/promociones',
		name: 'message.promotions',
		icon: 'mdi-fire',
		children: null,
	},
	{
		path: '/reservaciones',
		name: 'message.reservations',
		icon: 'mdi-calendar',
		children: null,
	},
	{
		path: '/locales',
		name: 'message.shops',
		icon: 'mdi-castle',
		children: null,
	},
];

export const adminPanelMenus = [
	{
		path: '/dashboard/reports',
		name: 'message.dashboard',
		icon: 'mdi-view-dashboard',
		children: null,
		active: true,
		permissions: 'see_dashboard',
	},
	{
		path: '',
		name: 'message.management',
		icon: 'mdi-file-document-box',
		active: false,
		permissions: [
			'salons_manage',
			'tables_manage',
			'categories_manage',
			'command_zones_manage',
			'unit_types_manage',
		],
		children: [
			{
				name: 'message.salons',
				path: '/dashboard/salons',
				permissions: 'salons_manage',
			},
			{
				name: 'message.tables',
				path: '/dashboard/tables',
				permissions: 'tables_manage',
			},
			{
				name: 'message.categories',
				path: '/dashboard/categories',
				permissions: 'categories_manage',
			},
			{
				name: 'message.commandZones',
				path: '/dashboard/command-zones',
				permissions: 'command_zones_manage',
			},
			{
				name: 'message.unitTypes',
				path: '/dashboard/unit-types',
				permissions: 'unit_types_manage',
			},
		],
	},
	{
		path: '',
		name: 'message.products',
		icon: 'mdi-food',
		active: false,
		permissions: ['menu_manage', 'ingredients_manage'],
		children: [
			{
				name: 'message.menu',
				path: '/dashboard/items',
				permissions: 'menu_manage',
			},
			{
				name: 'message.ingredients',
				path: '/dashboard/supplies',
				permissions: 'ingredients_manage',
			},
			{
				name: 'message.recipes',
				path: '/dashboard/recipes',
				permissions: 'ingredients_manage',
			},
			{
				name: 'message.movements',
				path: '/dashboard/movements',
				permissions: 'menu_manage',
			},
			{
				name: 'message.inventories',
				path: '/dashboard/inventories',
				permissions: 'menu_manage',
			},
			{
				name: 'message.inventoryHistories',
				path: '/dashboard/inventory-histories',
				permissions: 'see_inventory',
			},
		],
	},
	{
		path: '',
		name: 'message.sales',
		icon: 'mdi-cart',
		active: false,
		permissions: [
			'generate_commands',
			'see_vouchers',
			'see_orders',
			'see_commands',
			'generate_summaries',
			'voided_vouchers',
			'customers_manage',
		],
		children: [
			{
				name: 'message.newSale',
				path: '/dashboard/sale',
				permissions: 'generate_commands',
			},
			// {
			// 	name: 'message.documents',
			// 	path: '/dashboard/documents',
			// 	permissions: 'see_vouchers',
			// },
			{
				name: 'message.commands',
				path: '/dashboard/commands',
				permissions: 'see_commands',
			},
			{
			   name: "message.ordersOnline",
			   path: "/dashboard/orders-list",
			   permissions: 'see_orders',
			},
			// {
			// 	name: 'message.summaries',
			// 	path: '/dashboard/summaries',
			// 	permissions: 'generate_summaries',
			// },
			// {
			// 	name: 'message.voided',
			// 	path: '/dashboard/voided',
			// 	permissions: 'voided_vouchers',
			// },
			{
				name: 'message.customers',
				path: '/dashboard/people/customers',
				permissions: 'customers_manage',
			},
		],
	},
	{
		path: '',
		name: 'message.cash',
		icon: 'mdi-cash-multiple',
		active: false,
		permissions: ['to_do_transactions_in_cash', 'cash_manage'],
		children: [
			{
				name: 'message.salesReceivables',
				path: '/dashboard/sales-receivables',
				permissions: 'to_do_transactions_in_cash',
			},
			{
				name: 'message.openings',
				path: '/dashboard/openings',
				permissions: 'to_do_transactions_in_cash',
			},
			{
				name: 'message.openingsHistory',
				path: '/dashboard/openings-history',
				permissions: 'to_do_transactions_in_cash',
			},
			{
				name: 'message.cashManage',
				path: '/dashboard/cashes',
				permissions: 'cash_manage',
			},
		],
	},
	{
		path: '',
		name: 'message.reports',
		icon: 'mdi-chart-bar',
		active: false,
		permissions: ['see_report_of_sales'],
		children: [
			{
				name: 'message.documents',
				path: '/dashboard/report/documents',
				permissions: 'see_report_of_sales',
			},
			{
				name: 'message.commands',
				path: '/dashboard/report/commands',
				permissions: 'see_report_of_sales',
			},
		],
	},
	{
		path: '',
		name: 'message.configurations',
		icon: 'mdi-settings',
		active: false,
		permissions: [
			'permissions_manage',
			'roles_manage',
			'users_manage',
			'tasks_manage',
			'establishments_manage',
			'configurations_manage',
			'waiters_manage',
		],
		children: [
			{
				name: 'message.usersManage',
				path: '',
				permissions: ['permissions_manage', 'roles_manage', 'users_manage'],
				children: [
					{
						path: '/dashboard/permissions',
						name: 'message.permissions',
						active: false,
						permissions: 'permissions_manage',
					},
					{
						path: '/dashboard/roles',
						name: 'message.roles',
						active: false,
						permissions: 'roles_manage',
					},
					{
						path: '/dashboard/users',
						name: 'message.users',
						active: false,
						permissions: 'users_manage',
					},
				],
			},
			{
				name: 'message.tasks',
				path: '/dashboard/tasks',
				permissions: 'tasks_manage',
			},
			{
				name: 'message.establishments',
				path: '/dashboard/establishments',
				permissions: 'establishments_manage',
			},
			{
				name: 'message.distributionAreas',
				path: '/dashboard/distribution-areas',
				permissions: 'configurations_manage',
			},
			{
				name: 'message.correlatives',
				path: '/dashboard/correlatives',
				permissions: 'configurations_manage',
			},
			{
				name: 'message.settings',
				path: '/dashboard/configurations',
				permissions: 'configurations_manage',
			},
			{
				name: 'message.waiters',
				path: '/dashboard/waiters',
				permissions: 'waiters_manage',
			},
		],
	},
	{
		path: '/',
		name: 'message.goToSite',
		icon: 'mdi-home',
		active: false,
		children: null,
		permissions: 'see_dashboard',
	},
];
