import Vue from 'vue';
import VueRouter from 'vue-router';
import Nprogress from 'nprogress';

import userRoutes from './user';
import adminRoutes from './admin';
const NotFound = () => import('Views/NotFound.vue');

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		userRoutes,
		adminRoutes,
		{
			path: '*',
			component: NotFound,
			name: 'NotFound',
		},
	],
});

function nextCheck(context, middleware, index) {
	const nextMiddleware = middleware[index];

	if (!nextMiddleware) return context.next;

	return (...parameters) => {
		context.next(...parameters);
		const nextMidd = nextCheck(context, middleware, index + 1);

		nextMiddleware({ ...context, next: nextMidd });
	};
}

router.beforeEach((to, from, next) => {
	if (to.meta.middleware) {
		const middleware = Array.isArray(to.meta.middleware)
			? to.meta.middleware
			: [to.meta.middleware];

		const ctx = {
			from,
			next,
			router,
			to,
		};

		const nextMiddleware = nextCheck(ctx, middleware, 1);

		return middleware[0]({ ...ctx, next: nextMiddleware });
	}
	Nprogress.start();
	return next();
});

router.afterEach(() => {
	Nprogress.done();
	setTimeout(() => {
		const contentWrapper = document.querySelector('html');
		if (contentWrapper !== null) {
			contentWrapper.scrollTop = 0;
		}
	}, 100);
});

export default router;
