export default function guest({ next, router }) {
	let loggedUser = localStorage.getItem('user');
	const accessToken = document.cookie.replace(
		/(?:(?:^|.*;\s*)access_token=\s*([^;]*).*$)|^.*$/,
		'$1'
	);

	if (accessToken) {
		if (loggedUser.type == 'user') {
			return router.push({ name: 'home' });
		} else {
			// return router.push({ name: 'Reports' });
		}
	}

	return next();
}
